<template>
  <div class="container">
    <div class="search" style="display:flex">
      <el-form ref="form" style="flex:1" :inline="true" :model="search" class="demo-form-inline">
        <el-form-item>
          <el-input v-model="search.kname" @change="Search" placeholder="名称"></el-input>
        </el-form-item>
      </el-form>
      <div>
        <router-link :to="'/iot/app/emailedit/0'" style="margin-right：10px">
          <el-button type="primary" size="small">新建</el-button>
        </router-link>
      </div>
    </div>
    <!-- 列表 -->
    <el-table border v-bind:data="list">
      <el-table-column label="发件人邮箱账号">
        <template v-slot="scope">
          <span>{{ scope.row.from }}</span>
        </template>
      </el-table-column>
      <el-table-column label="发件服务器域名">
        <template v-slot="scope">
          <span>{{ scope.row.host }}</span>
        </template>
      </el-table-column>
      <el-table-column label="发件服务器端口">
        <template v-slot="scope">
          <span>{{ scope.row.port }}</span>
        </template>
      </el-table-column>
      <el-table-column label="所属区域">
        <template v-slot="scope">
          <span>{{ scope.row.region }}</span>
        </template>
      </el-table-column>
      <el-table-column label="是否采用加密">
        <template v-slot="scope">
          <span>{{ scope.row.secure=='true'?'是':'否' }}</span>
        </template>
      </el-table-column>
      <el-table-column label="发件人昵称">
        <template v-slot="scope">
          <span>{{ scope.row.unit }}</span>
        </template>
      </el-table-column>
      <el-table-column label="发件人帐号">
        <template v-slot="scope">
          <span>{{ scope.row.username }}</span>
        </template>
      </el-table-column>
      <el-table-column label="操作">
        <template v-slot="scope">
          <router-link :to="'/iot/app/emailedit/'+scope.row.id" style="margin-right:10px">
            <el-button type="primary" size="small">修改</el-button>
          </router-link>
          <el-button type="danger" size="small" @click="deleteEmail(scope.row.id)">删除</el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      @current-change="handleCurrentChange"
      v-model:currentPage="search.page"
      :page-size="search.limit"
      layout="total, prev, pager, next"
      :total="total">
    </el-pagination>
  </div>
</template>

<script type="text/ecmascript-6">
import api from '../../../../axios/emial'
export default {
  name: '',
  mixins: [],
  components: {},
  props: {},
  data () {
    return {
      search: {
        page: 1,
        limit: 10,
        kname: ''
      },
      list: [],
      total: 0,
    }
  },
  computed: {},
  watch: {},
  methods: {
    handleCurrentChange(val) {
      this.search.page = val
      this.Search()
    },
    Search() {
      api.AppEmailList(this.search).then(res => {
        if (res.data.code == 200) {
          this.list = res.data.data.data
          this.total = res.data.data.page_info.total
          return
        }
        this.$message.error('获取失败' + res.data.msg)
      })
    },
    deleteEmail(item) {
      this.$confirm('是否删除该邮箱配置?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        // 删除请求
        api.AppEmailDelete({id: item}).then(res => {
          if (res.data.code == 200) {
            this.$message({
              type: 'success',
              message: '删除成功!'
            })
            this.Search()
            return
          }
          this.$message.error('删除失败' + res.data.msg)
        })
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '取消删除'
        });
      })
    },
  },
  filters: {},
  mounted () {
    this.Search()
  },
  created () { }
}
</script>

<style scoped lang="stylus" rel="stylesheet/stylus">
</style>
